<template>
  <JakoContent/>
  <!-- <VueperSlides/>
  <VueperSlide/>-->
</template>

<script>
import JakoContent from './components/JakoContent.vue'



export default {


  name: 'App',
  components: {
    JakoContent
  },

  computed:{
    
  }
}
</script>

<style>
#app {
  font-family: 'Kanit';
}

</style>
