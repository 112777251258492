import { createApp } from 'vue'
import App from './App.vue'
import VueSmoothScroll from 'vue3-smooth-scroll'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

/*

setting responesive goldble by VUE3MQ

import { Vue3Mq , MqResponsive} from "vue3-mq";


const app = createApp();
app.component('mq-responsive', MqResponsive);


app.use(Vue3Mq, {
    breakpoints: {
        mobile: 0,
        tablet: 900,
        laptop: 1250,
    }
})*/
const app = createApp(App)
app.use(VueSmoothScroll)
app.component("font-awesome-icon",FontAwesomeIcon)
app.mount('#app')
