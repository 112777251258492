<style>
/* call CSS mainjako.css */
@import "../../public/css/mainjako.css";
@import "../../public/css/mainbodyjako.css";
</style>

<template>
  <div>
    <header>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Kanit:400,500,600,700,900&amp;subset=latin-ext&amp;display=swap"
      />
    </header>
    <div>
      <div id="page-top"></div>
      <nav id="mainNav" class="navbar navbar-light navbar-expand-xl fixed-top">
        <div class="container">
          <div>
            <a href="#page-top">
              <img class="img-fluid" src="../../public/images/Logo_WeAreJako_version2.svg" />
            </a>
          </div>
          <button
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            class="navbar-toggler navbar-toggler-right"
            type="button"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
          <!-- <font-awesome-icon icon="fa-solid fa-bars" /> -->
            <!-- <i class="fa fa-align-justify"></i> -->
            <img src="../../public/images/btnToggle.png" />
          </button>
          <div id="navbarResponsive" class="collapse navbar-collapse">
            <ul class="navbar-nav ms-auto" style="align: center">
              <li class="nav-item nav-aling-cen">
                <a href="#page-top" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" v-smooth-scroll="{ offset: 0, updateHistory: false ,duration: 100}">About Us</a>
              </li>
              <li class="nav-item nav-aling-cen" >
                <a href="#">Token</a>
              </li>
              <li class="nav-item nav-aling-cen">
                <a href="#jakocoin-vrverse-info" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" v-smooth-scroll="{ offset: 10, updateHistory: false ,duration: 100}">VR Metaverse</a
                >
              </li>
              <li class="nav-item nav-aling-cen">
                <a href="#jakocoin-Games-info" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" v-smooth-scroll="{ offset: -20, updateHistory: false ,duration: 100}">Games</a>
              </li>
              <li class="nav-item nav-aling-cen">
                <a href="#jako-feature-info" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" v-smooth-scroll="{ offset: -50, updateHistory: false ,duration: 100}">Features</a>
              </li>
              <li class="nav-item nav-aling-cen">
                <a href="#allocation-token" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" aria-expanded="false" v-smooth-scroll="{ offset: -20, updateHistory: false ,duration: 100}">Allocation Token</a>
              </li>
              <li class="nav-item nav-aling-cen">
                <a href="#partners-info" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" v-smooth-scroll="{ offset: 0, updateHistory: false, duration: 100}">Partners</a>
              </li>
              <li class="nav-item nav-aling-cen">
                <a href="#contact-main-info" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" v-smooth-scroll="{ offset: 0, updateHistory: false, duration: 100}">Contacts</a>
              </li>
              <li class="nav-item nav-aling-cen">
                <div class="connect-bnt">
                  <p style="margin-top: 7px">Market</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <!-- <nav class="navbar-head">
   
    <ul >
      <div class="mainlogo"> <a href="index.html" > <img src="../../public/images/Logo_WeAreJako_version2.svg"> </a></div>
    <li><a href="#">Abount Us</a></li> 
    <li><a href="#">Token</a></li> 
    <li><a href="#jakocoin-vrverse-info" v-smooth-scroll="{offset:-10,updateHistory:false}">VR Metaverse</a></li> 
    <li><a href="#jakocoin-Games-info">Games</a></li> 
    <li><a href="#jako-feature-info">Features</a></li> 
    <li><a href="#allocation-token">Allocation Token</a></li> 
    <li><a href="#partners-info">Partners</a></li> 
    <li><a href="#contact-main-info">Contacts</a></li>
    <div class="connect-bnt"><p style="margin-top:7px; background-color: transparent;">Market</p></div>       
    </ul>

      </nav> -->
      <img src="../../public/images/btnToggle.png" />
      <section class="header-info">
        <div class="container">
          <div class="row">
            <div class="col-lg-10 mx-auto">
              <div class="title-head-1 text-green col-lg-10 mx-auto">
                Who we are
                <span class="header-title-symbol">?</span>
              </div>
              <div class="header-content">
                We are a joint venture company from Japanese and Korean
                nationality<br />
                developing games in the form of Virtual Reality (VR) through
                Unity Platform with over 8 years of work experience.<br />
                We are seeing the transformation of the future world with the
                Metaverse expanding more and more every day.<br />
                Especially the BlockChain technology that will play a big role
                in the future.<br />
                So we started to take this technology into action and create a
                whole new world where<br />
                we could talk and do a lot of activities within our VR
                Jakoverse.
              </div>
              
              <img
                class="img-fluid mx-auto d-block p-2"
                src="../../public/images/whoweare.svg"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <section id="jako-aboutus" class="pt-md-4">
    <div class="container">
      <div class="row align-item-center px-lg-4">
        <div class="col-lg-6 col-sm-10 col-11 mx-auto">
          <div>
            <div class="coin-title text-green">
              What can <br />
              <div class="coin-titlemini text-green">
                JAKO Coins do <span class="coin-title-symbol">?</span>
              </div>
            </div>
            <div></div>
            <div class="coin-content">
              JAKO Coin, we developed to be the medium of the great market
              system. Not only can NFT be traded within the market. Users can
              deposit them to receive IDO privileges, exclusive NFTs, or other
              rewards. They can also be used to create NFT Art to gain market
              share, or create NFTs for exchanging with tangible products.
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-7 col-sm-9 mx-auto">
          <img
            class="img-fluid mx-auto"
            src="../../public/images/jakocoinhave.svg"
          />
        </div>
        <!-- <div class="col-xxl-5 col-xl-6 col-lg-6 col-md-8 mx-auto">
          <img
            class="img-fluid mx-auto"
            src="../../public/images/jakocoinhave.svg"
          />
        </div> -->
      </div>
    </div>
  </section>

  <section id="jakocoin-vrverse-info">
    <div class="border-green"></div>
    <div class="container">
      <div class="vrverse-titlemeta">
        <div><span style="font-size: 24px">capabilities of</span></div>
        <div class="title-head-1 text-green pt-3 py-4" style="line-height:100%;">VR Metaverse</div>
        <div class="coin-content col-xxl-7 col-xl-7 col-lg-10 col-11 mx-auto">
          Our VR Jakoverse will be a great game system. Players can bring
          various NFTs to display within the VR Jakoverse, whether for aesthetic
          purposes. or to receive many benefits Users can create a community,
          meet, chat and play various Play to earn games through our VR
          Jakoverse system.
        </div>
      </div>

      <div class="row vrverse-platform-info px-md-4">
        <div class="col-xxl-5 col-xl-5 col-lg-6 col-md-7 col-8 mx-auto">
          <img class="img-fluid" src="../../public/images/vrmetavers.svg" />
        </div>

        <div class="col-xxl-6 col-xl-6 col-lg-6 col-11 mt-xxl-5 mx-auto">
          <div class="mt-md-5"></div>
          <div class="title-head-2" style="line-height: 106%;">
            What platforms can <br />
            VR Jakoverse be used for<span style="color: #00d967"> ?</span>
          </div>
          <div class="vrverse-content-line coin-content mt-3">
            Are you thinking that you don't have a VR game machine, how can you
            use it? We can say that this system is not limited to people with VR
            devices alone. This system can be developed on various platforms
            such as VR, PC, and Mobile, so you can worry about it.
          </div>
        </div>
      </div>
    </div>

    <div class="border-green mt-5"></div>
  </section>
   
  <section id="jakocoin-Games-info">
    <div class="container">
      <div class="title-head-1 text-green mt-5 mb-3">Games</div>
       <!-- FeatureSLide show when screen >= lg -->
      <div class="gamesslide-container d-none d-lg-block">
        <vueper-slides
          autoplay
          :infinite="true"
          :arrows="false"
          :aria-label="`gameSlide`"
          :pause-on-hover="pauseOnHover"
          fixedHeight="780px"
          :slideContentOutside="false"
          :touchable="false"
          duration="4000"
          class="no-shadow"
        >
          <vueper-slide v-for="(slidegame, i) in slidesgames" :key="i">
            <template #content>
              <div class="game-slide-bg" :class="slidegame.gamebgclass" :style="slidegame.gamebg">
                <div class="jakoverse-logo-lg d-none d-lg-block">
                  <img class="img-fluid" :src="slidegame.gamelogo" />
                </div>
                <div class="jakoverse-logo-md d-lg-none d-md-block mx-auto">
                  <img class="img-fluid" :src="slidegame.gamelogo" />
                </div>
                <div v-html="slidegame.gamebtnlg"></div>
                <div class="game-detail col-10 mx-auto mt-5">
                  <div class="game-detail-title">{{ slidegame.gametitle }}</div>
                  <div class="game-detail-content pb-4">
                    {{ slidegame.gamedes }}
                  </div>
                  <div v-html="slidegame.gamebtnmd"></div>
                </div>
              </div>
            </template>

            <template #bullet="{ bulletIndexes, goToSlide, currentSlide }">
              <span
                v-for="(slideIndex, i) in bulletIndexes"
                :key="i"
                :class="{ active: currentSlide === slideIndex }"
                @click="goToSlide(slideIndex)"
              >
                <i class="icon">{{
                  active ? "check_circle" : "radio_button_unchecked"
                }}</i>
              </span>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
       <!-- FeatureSLide show when screen = md -->
      <div class="gamesslide-container d-lg-none d-md-block d-none">
        <vueper-slides
        autoplay
          :infinite="true"
          :arrows="false"
          :aria-label="`gameSlide`"
          :pause-on-hover="pauseOnHover"
          fixedHeight="780px"
          :slideContentOutside="false"
          :touchable="false"
          duration="4000"
          class="no-shadow"
        >
          <vueper-slide v-for="(slidegame, i) in slidesgames" :key="i">
            <template #content>
              <div class="game-slide-bg-md" :class="slidegame.gamebgclass" :style="slidegame.gamebg">
                <div class="jakoverse-logo-lg d-none d-lg-block">
                  <img class="img-fluid" :src="slidegame.gamelogo" />
                </div>
                <div class="jakoverse-logo-md d-lg-none d-md-block mx-auto">
                  <img class="img-fluid" :src="slidegame.gamelogo" />
                </div>
                <div v-html="slidegame.gamebtnlg"></div>
                <div class="game-detail col-10 mx-auto mt-5">
                  <div class="game-detail-title">{{ slidegame.gametitle }}</div>
                  <div class="game-detail-content pb-4">
                    {{ slidegame.gamedes }}
                  </div>
                  <div v-html="slidegame.gamebtnmd"></div>
                </div>
              </div>
            </template>

            <template #bullet="{ bulletIndexes, goToSlide, currentSlide }">
              <span
                v-for="(slideIndex, i) in bulletIndexes"
                :key="i"
                :class="{ active: currentSlide === slideIndex }"
                @click="goToSlide(slideIndex)"
              >
                <i class="icon">{{
                  active ? "check_circle" : "radio_button_unchecked"
                }}</i>
              </span>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
       <!-- FeatureSLide show when screen = sm -->
      <div class="gamesslide-container d-md-none d-sm-block d-none">
        <vueper-slides 
          autoplay
          :infinite="true"
          :arrows="false"
          :aria-label="`gameSlide`"
          :pause-on-hover="pauseOnHover"
          fixedHeight="820px"
          :slideContentOutside="false"
          :touchable="false"
          duration="4000"
          class="no-shadow"
        >
          <vueper-slide v-for="(slidegame, i) in slidesgames" :key="i">
            <template #content>
              <div class="game-slide-bg-md" :class="slidegame.gamebgclass" :style="slidegame.gamebg">
                <div class="jakoverse-logo-lg d-none d-lg-block">
                  <img class="img-fluid" :src="slidegame.gamelogo" />
                </div>
                <div class="jakoverse-logo-md d-lg-none d-md-block mx-auto">
                  <img class="img-fluid" :src="slidegame.gamelogo" />
                </div>
                <div v-html="slidegame.gamebtnlg"></div>
                <div class="game-detail col-10 mx-auto mt-5">
                  <div class="game-detail-title">{{ slidegame.gametitle }}</div>
                  <div class="game-detail-content pb-4">
                    {{ slidegame.gamedes }}
                  </div>
                  <div v-html="slidegame.gamebtnmd"></div>
                </div>
              </div>
            </template>

            <template #bullet="{ bulletIndexes, goToSlide, currentSlide }">
              <span
                v-for="(slideIndex, i) in bulletIndexes"
                :key="i"
                :class="{ active: currentSlide === slideIndex }"
                @click="goToSlide(slideIndex)"
              >
                <i class="icon">{{
                  active ? "check_circle" : "radio_button_unchecked"
                }}</i>
              </span>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>

      <div class="gamesslide-container d-sm-none d-xs-block" >
        <vueper-slides
          autoplay
          :infinite="true"
          :arrows="false"
          :aria-label="`gameSlide`"
          :pause-on-hover="pauseOnHover"
          fixedHeight="870px"
          :slideContentOutside="false"
          :touchable="false"
          duration="4000"
          class="no-shadow"
        >
          <vueper-slide v-for="(slidegame, i) in slidesgames" :key="i">
            <template #content>
              <div class="game-slide-bg-md" :class="slidegame.gamebgclass" :style="slidegame.gamebg">
                <div class="jakoverse-logo-lg d-none d-lg-block">
                  <img class="img-fluid" :src="slidegame.gamelogo" />
                </div>
                <div class="jakoverse-logo-md d-lg-none d-md-block mx-auto">
                  <img class="img-fluid" :src="slidegame.gamelogo" />
                </div>
                <div v-html="slidegame.gamebtnlg"></div>
                <div class="game-detail col-10 mx-auto mt-5">
                  <div class="game-detail-title">{{ slidegame.gametitle }}</div>
                  <div class="game-detail-content pb-4">
                    {{ slidegame.gamedes }}
                  </div>
                  <div v-html="slidegame.gamebtnmd"></div>
                </div>
              </div>
            </template>

            <template #bullet="{ bulletIndexes, goToSlide, currentSlide }">
              <span
                v-for="(slideIndex, i) in bulletIndexes"
                :key="i"
                :class="{ active: currentSlide === slideIndex }"
                @click="goToSlide(slideIndex)"
              >
                <i class="icon">{{
                  active ? "check_circle" : "radio_button_unchecked"
                }}</i>
              </span>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>

    </div>
  </section>

  <section id="jakocoin-coin-info" class="my-5 py-3">
    <div class="container">
      <div class="row">
        <div class="col-xxl-6 col-xl-6 col-11 mt-5 mx-auto">
          <div
            class="title-head-1 text-green"
            style="text-align: left; line-height: 70%"
          >
            Where do <br />
            <div class="title-head-2 text-green">
              we get our income <span class="coin-title-symbol">?</span>
            </div>
          </div>

          <div class="coin-content mt-4">
            Looking at the $JAKO tokenomic system, <br />
            you can see that we received a portion of the tokens from the Team
            Developer, <br />
            but that's not a sustainable income. It's just the funding to help
            us build on the projects that will take place within our Metaverse.
            <br />
            And in other projects We do not accept any share of coins.<br />
            We choose to grant rights only to users who have the right to
            determine the direction of the market. <br />
            We only expect to earn from trading fees within the Marketplace
          </div>
        </div>

        <!-- <div class="col-xxl-5 col-xl-5 col-lg-6 col-11 mx-auto"> -->
        <div class="mx-auto" style="max-width:556px;">
          <img class="img-fluid" src="../../public/images/cangetincome.svg" />
        </div>
      </div>
    </div>
  </section>

  <section id="jako-feature-info">
    <div class="container">
      <div class="title-head-1 text-green">Features</div>
      <div class="feature-Slideshows">
        <!-- FeatureSLide show when screen = xxl xl -->
        <div class="feature-main mx-auto d-none d-xl-block">
          <vueper-slides
            autoplay
            :bullets="false"
            :pause-on-hover="pauseOnHover"
            :aria-label="`featureSlide`"
            :visible-slides="3"
            :gap="0"
            :slide-ratio="1 / 4"
            :arrows-outside="false"
            class="no-shadow"
            fixedHeight="500px"
            :slideContentOutside="false"
            :touchable="false"
            :duration="4000"
          >
            <template #arrow-left>
              <i class="feature-pev btn-slide-lg" />
            </template>
            <template #arrow-right>
              <i class="feature-next btn-slide-lg" />
            </template>
            <vueper-slide v-for="(slide, i) in slides" :key="i">
              <template #content>
                <div class="feature-frame">
                  <div class="icon-feature"><img :src="slide.feaimg" /></div>

                  <div class="body-feature">
                    <div class="feature-content-slide col-10 mx-auto">
                      <div class="text-green box-featuretitle">
                        {{ slide.featitle }}
                      </div>
                      <div
                        class="box-featuredetail"
                        v-html="slide.feades"
                      ></div>
                    </div>
                  </div>
                </div>
              </template>
            </vueper-slide>
          </vueper-slides>
        </div>
        <!-- FeatureSLide show when screen = lg -->
        <div class="feature-main mx-auto d-xl-none d-lg-block d-none">
          <vueper-slides
            autoplay
            :bullets="false"
            :pause-on-hover="pauseOnHover"
            :aria-label="`featureSlide`"
            :visible-slides="2"
            :gap="0"
            :slide-ratio="1 / 4"
            :arrows-outside="false"
            class="no-shadow"
            fixedHeight="500px"
            :slideContentOutside="false"
            :touchable="false"
            :duration="4000"
          >
            <template #arrow-left>
              <i class="feature-pev btn-slide-lg" />
            </template>
            <template #arrow-right>
              <i class="feature-next btn-slide-lg" />
            </template>
            <vueper-slide v-for="(slide, i) in slides" :key="i">
              <template #content>
                <div class="feature-frame">
                  <div class="icon-feature"><img :src="slide.feaimg" /></div>

                  <div class="body-feature">
                    <div class="feature-content-slide col-10 mx-auto">
                      <div class="text-green box-featuretitle">
                        {{ slide.featitle }}
                      </div>
                      <div
                        class="box-featuredetail"
                        v-html="slide.feades"
                      ></div>
                    </div>
                  </div>
                </div>
              </template>
            </vueper-slide>
          </vueper-slides>
        </div>
        <!-- FeatureSLide show when screen = md -->
        <div class="feature-main mx-auto d-lg-none d-md-block d-none">
          <vueper-slides
            autoplay
            :bullets="false"
            :pause-on-hover="pauseOnHover"
            :aria-label="`featureSlide`"
            :visible-slides="2"
            :gap="0"
            :slide-ratio="1 / 4"
            :arrows-outside="false"
            class="no-shadow"
            fixedHeight="500px"
            :slideContentOutside="false"
            :touchable="false"
            :duration="4000"
          >
            <template #arrow-left>
              <i class="feature-pev btn-slide-lg" />
            </template>
            <template #arrow-right>
              <i class="feature-next btn-slide-lg" />
            </template>
            <vueper-slide v-for="(slide, i) in slides" :key="i">
              <template #content>
                <div class="feature-frame-md">
                  <div class="icon-feature">
                    <img class="feature-icon-md" :src="slide.feaimg" />
                  </div>

                  <div class="body-feature-md">
                    <div class="feature-content-slide col-10 mx-auto">
                      <div class="text-green box-featuretitle">
                        {{ slide.featitle }}
                      </div>
                      <div
                        class="box-featuredetail"
                        v-html="slide.feades"
                      ></div>
                    </div>
                  </div>
                </div>
              </template>
            </vueper-slide>
          </vueper-slides>
        </div>
        <!-- FeatureSlide show when scree= sm -->
        <div class="feature-main mx-auto d-md-none d-sm-block d-none">
          <vueper-slides
            autoplay
            :bullets="false"
            :pause-on-hover="pauseOnHover"
            :aria-label="`featureSlide`"
            :visible-slides="1"
            :gap="0"
            :slide-ratio="1 / 4"
            :arrows-outside="false"
            class="no-shadow"
            fixedHeight="500px"
            :slideContentOutside="false"
            :touchable="false"
            :duration="4000"
          >
            <template #arrow-left>
              <i class="feature-pev btn-slide-lg" />
            </template>
            <template #arrow-right>
              <i class="feature-next btn-slide-lg" />
            </template>
            <vueper-slide v-for="(slide, i) in slides" :key="i">
              <template #content>
                <div class="feature-frame-md">
                  <div class="icon-feature">
                    <img class="feature-icon-md" :src="slide.feaimg" />
                  </div>

                  <div class="body-feature-md">
                    <div class="feature-content-slide col-10 mx-auto">
                      <div class="text-green box-featuretitle">
                        {{ slide.featitle }}
                      </div>
                      <div
                        class="box-featuredetail"
                        v-html="slide.feades"
                      ></div>
                    </div>
                  </div>
                </div>
              </template>
            </vueper-slide>
          </vueper-slides>
        </div>
        <!-- FeatureSlide show when scree= xs -->
        <div class="feature-main mx-auto d-sm-none d-xs-block">
          <vueper-slides
            :bullets="false"
            :pause-on-hover="pauseOnHover"
            :aria-label="`featureSlideXs`"
            :visible-slides="1"
            :gap="0"
            :slide-ratio="1 / 4"
            :arrows-outside="false"
            class="no-shadow"
            fixedHeight="500px"
            :touchable="false"
            :duration="3000"
          >
            <template #arrow-left>
              <i class="feature-pev btn-slide-xs" />
            </template>
            <template #arrow-right>
              <i class="feature-next btn-slide-xs" />
            </template>
            <vueper-slide v-for="(slide, i) in slides" :key="i">
              <template #content>
                <div class="feature-frame-xs">
                  <div class="icon-feature">
                    <img class="feature-icon-xs" :src="slide.feaimg" />
                  </div>

                  <div class="body-feature-xs">
                    <div class="feature-content-slide col-10 mx-auto">
                      <div class="text-green box-featuretitle">
                        {{ slide.featitle }}
                      </div>
                      <div
                        class="box-featuredetail"
                        v-html="slide.feades"
                      ></div>
                    </div>
                  </div>
                </div>
              </template>
            </vueper-slide>
          </vueper-slides>
        </div>
      </div>
    </div>
  </section>

  <section id="allocation-token">
    <div class="border-green" style="margin-top: 42px"></div>

    <div class="container">
      <div class="allocation-info">
        <div class="title-head-1 text-green mt-5 pt-3" style="line-height:100%;">Allocation Token</div>

        <div class="row px-xl-4 pt-5">
          <!-- <div
            class="
              col-xxl-6 col-xl-5 col-lg-10 col-12
              d-md-block d-sm-none d-none
            "
          > -->
          <div class="col-xxl-5 col-lg-5 col-md-5 mx-auto d-md-flex d-sm-none d-none">
            <img
              class="img-fluid" src="../../public/images/allocation_Token.svg"
            />
          </div>

          <div class="col-xxl-6 col-xl-7 col-11 tokenbar-content mx-auto mt-xl-5">
            <div class="row">
            <div class="col-xl-6 col-lg-5 allocation-graph mx-auto">
              <div class="progress-manage">
                Public Sale 2.5%
                <br /><br />
                <div class="token-bar">
                  <div class="token-bar-progress-pbsale"></div>
                </div>
              </div>

              <div class="progress-manage">
                Privete Sale 4.0%
                <br /><br />
                <div class="token-bar">
                  <div class="token-bar-progress-pvsale"></div>
                </div>
              </div>

              <div class="progress-manage">
                Partnership 6.0%
                <br /><br />
                <div class="token-bar">
                  <div class="token-bar-progress-pnship"></div>
                </div>
              </div>

              <div class="progress-manage">
                Team & Advisor 20.0%
                <br /><br />
                <div class="token-bar">
                  <div class="token-bar-progress-TeamandAd"></div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-5 allocation-graph mx-auto">
              <div class="progress-manage">
                Marketing & Community 10.0%
                <br /><br />
                <div class="token-bar">
                  <div class="token-bar-progress-MarketandCom"></div>
                </div>
              </div>

              <div class="progress-manage">
                Dex liquidity & Liquidity mining 15.0%
                <br /><br />
                <div class="token-bar">
                  <div class="token-bar-progress-DexandLiqM"></div>
                </div>
              </div>

              <div class="progress-manage">
                Farming Reward 27.5%
                <br /><br />
                <div class="token-bar">
                  <div class="token-bar-progress-FramRe"></div>
                </div>
              </div>

              <div class="progress-manage">
                Reserves 15.0%
                <br /><br />
                <div class="token-bar">
                  <div class="token-bar-progress-Reserves"></div>
                </div>
              </div>
            </div>
            </div>
            <div class="mt-3 text-center text-green coin-titlemini">Total : 500,000,000</div>
          </div>
          
        </div>
      </div>
    </div>
    <div class="border-green mt-5"></div>
  </section>

  <section id="partners-info">
      <div class="title-head-1 text-green pt-4">Partners</div>
      <div class="mt-4">
        <div class="row d-flex justify-content-center">
          <div class="partners-icon p-3">
            <a href="http://i3dmeta.com/" target="_blank">
            <img class="img-fluid mt-3" src="../../public/images/i3dmetab.png"
             onmouseover="this.src='images/i3dmeta_cz.png'" onmouseout="this.src='images/i3dmetab.png'"/>
            <!-- <div id="partner-1" > </div> -->
            </a>
          </div>
          <div  class="partners-icon p-3">
            <a href="http://u-106.com/" target="_blank">
            <img class="img-fluid"  src="../../public/images/egg_braine.png"
            onmouseover="this.src='images/egg_brain_cz.png'" onmouseout="this.src='images/egg_braine.png'" />
           <!-- <div id="partner-2" > </div> -->
          </a>
          </div>
        </div>
      </div>
  </section>
  <section id="contact-main-info">
    <div class="container">
        <div class="title-head-1 text-green">Contact Us</div>
        <div class="row d-flex justify-content-center">
          <div class="social-icon m-2">
            <a href="https://t.me/+P-m3hlIxWlkyOWI1"> <img class="img-fluid" src="../../public/images/talegram_logo.svg" /></a>
          </div>
          <div class="social-icon m-2">
            <a href="https://twitter.com/WeAreJako"><img class="img-fluid" src="../../public/images/twitter_logo.svg" /></a>
          </div>
          <div class="social-icon m-2">
            <a href="https://www.facebook.com/WeAreJako/"><img class="img-fluid" src="../../public/images/facebook_logo.svg" /></a>
          </div>
          <div class="social-icon m-2">
            <a href="mailto:support@wearejako.com"><img class="img-fluid" src="../../public/images/email_logo.svg" /></a>
          </div>
          
        </div>

        <div class="d-flex col-3 mx-auto" style="width:316px; overflow: auto;">
          
          
        </div>
        <div class="contact-us-copy mb-3">
          CopyrightⒸ 2022 WE ARE JAKO •<span class="text-green">
            support@wearejako.com </span
          >• All rights reserved.
        </div>
    </div>
  </section>
</template>


<script>
/* call use data&image by json file  */
import jsonslide from "../JsonFile/slide_data.json";

/* call slides by VueperSliders */
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

/*  
 call use responesive by VUE3MQ
 import { MqResponsive } from "vue3-mq";*/

export default {
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      slides: jsonslide[0].featureslides,
      slidesgames: jsonslide[1].slidesgames,
    };
  },
};
</script>


